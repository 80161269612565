<template>
    <div v-if="posts.length">
        <h3>Список пользователей</h3>
        <transition-group name="post-list">
            <post-item
                v-for="post in posts"
                :post="post"
                :key="post.id"
                @remove="$emit('remove', post)"
            />
        </transition-group>
    </div>
    <h2 v-else style="color: red">
      Спиок пользователей пуст
    </h2>
</template>


<script>
import PostItem from './PostItem.vue'
export default {
    components: { PostItem },
    props:{
        posts:{
            type: Array,
            required: true
        }
    }
}
</script>

<style scoped>
.post-list-item{
  display: inline-block;
  margin-right: 10px;
}

.post-list-enter-active,
.post-list-leave-active {
  transition: all .4s ease;
}

.post-list-enter-from,
.post-list-leave-to{
  opacity: 0;
  transform: translateX(-100%);
}

.post-list-move{
  transition: transform .4s ease;
}
</style>