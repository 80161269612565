import MyButton from './MyButton'
import MyInput from "./MyInput";
import MyDialog from "./MyDialog";
import MySelect from "./MySelect";


export default[
    MyButton,
    MyInput,
    MyDialog,
    MySelect
]